import React from "react";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";

const Page = () => {
  return (
    <Layout>
      <SearchEngineOptimization
        title=""
        description=""
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-12 md:pt-16 pb-20 md:pb-32">
        <div className="container">
          <header className="text-center max-w-3xl mx-auto mb-10 md:mb-14">
            <h1>Home Affordability Calculator</h1>
          </header>

          <iframe
            src="https://mortgage-tools.flywheelsites.com/home-affordability-calculator/"
            width="100%"
            height="100%"
            title="Mortgage Calculator"
            frameBorder="0"
            className="h-[1200px] md:h-[925px]"
          ></iframe>
        </div>
      </section>
    </Layout>
  );
};

export default Page;
